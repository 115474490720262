<template>
  <div class="">
		<div class="trends_list">
			<div class="list_item" v-for="(childItem,childIndex) in state.listObj">
				<div class="item_right">
					<div class="right_tit">
						<span>{{childItem.regTime}}</span>
					</div>
					<div class="right_cont">
						<div class="cont_tit" style="display: flex;justify-content: space-between;">
							<div>
								操作人：{{childItem.opName}}
							</div>
						</div>
						<div class="cont_name">操作日志：{{childItem.opLog}}</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated, defineEmits } from 'vue'
	export default{
		props:{
			id:String
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const state = reactive({
				listObj: {},
				projectId: '',
				caseData: {}
				
			})
			onMounted(()=>{
				state.projectId = props.id
				event.detail()
			})
			const event = {
				detail(){
					console.log(state.projectId)
					proxy.$axios.icmCaseLogList({caseId:state.projectId}).then(res=>{
						if(res.data.code == 200){
							state.listObj = res.data.data
						}
					})
				},
			}
			return {
				state,
				event
			}
		},
		
	}
</script>

<style>
	.trends{
		height: calc(100vh - 100px);
		height: calc(var(--vh, 1vh) * 100 - 100px);
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		text-align: left;
	}
	.trends .trends_list{
		padding-bottom: 40px;
	}
	.trends_list .list_tit{
		height: 46px;
		line-height: 46px;
		background-color: #f7f7f7;
		color: 999;
		font-size: 14px;
		padding-left: 10px;
	}
	.trends_list .list_item{
		background-color: #fff;
		display: flex;
		padding: 10px;
	}
	.trends_list .item_left div{
		width: 40px;
		height: 40px;
		display: flex;
		justiy-content: center;
		border-radius: 20px;
		overflow: hidden;
	}
	.trends_list .item_left div img{
		height: 100%;
	}
	.trends_list .item_right{
		width: 100%;
		margin: 0 10px;
	}
	.trends_list .right_tit{
		font-size: 16px;
		color: #666;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}
	.trends_list .right_tit span:last-child{
		font-size: 12px;
		color: #aaa;
	}
	.right_cont{
		background-color: #f7f7f7;
		border-radius: 10px;
		padding: 20px 10px;
	}
	.cont_tit{
		color: #666;
		font-size: 12px;
	}
	.cont_txt{
		font-size: 12px;
		color: #939393;
	}
	.cont_name{
		color: #333;
		font-size: 14px;
		margin: 10px 0;
		overflow: hidden;
	}
	.trends_tab{
		font-size: 12px;
		padding: 5px;
		border-radius: 5px;
		margin-right: 10px;
		color: #fff;
	}
	.trends_tab1{
		color: #fff;
		font-size: 13px;
		border-radius: 5px;
		padding: 5px;
	}
	.trends_tab2{
		display: inline-block;
		color: #fff;
		border-radius: 11px;
		padding: 2px 5px 3px;
	}
	.chen{
		color: #ED9956;
		background-color: #FFF1E5;
	}
	.blue{
		color: #546FD5;
		background-color: #EDEFFF;
	}
	.lan_bg{
		background-color: #47CCAB;
	}
	.blue_bg{
		background-color: #6C95EA;
	}
	.chen_bg{
		background-color: #F58068;
	}
	.longBlue_bg{
		background-color: #46B1EA;
	}
	.vs{
		display: inline-block;
		width: 17px;
		height: 17px;
		background-image: url('../../assets/imgs/vs.png');
		background-size: 100%;
		background-repeat: no-repeat;
		margin: 0 8px;
		vertical-align: middle;
	}
	.jiantou_icon{
		display: inline-block;
		width: 12px;
		height: 12px;
		background-image: url('../../assets/imgs/jian_icon.png');
		background-size: 100%;
		background-repeat: no-repeat;
		margin: 0 10px;
		vertical-align: middle;
	}
	.trends .nut-countdown{
		font-size: 12px;
		line-height: 18px;
	}
	.file_icon{
		display: inline-block;
		width: 26px;
		height: 32px;
		background-size: 100%;
		background-position: top center;
		background-repeat: no-repeat;
		vertical-align: middle;
		margin-right: 5px;
		background-image: url('/src/assets/imgs/other.png');
	}
	.jpg_icon{
		background-image: url('/src/assets/imgs/jpg.png');
	}
	.excel_icon{
		background-image: url('/src/assets/imgs/excel.png');
	}
	.ppt_icon{
		background-image: url('/src/assets/imgs/ppt.png');
	}
	.pdf_icon{
		background-image: url('/src/assets/imgs/pdf.png');
	}
	.word_icon{
		background-image: url('/src/assets/imgs//word.png');
	}
	.video_icon{
		background-image: url('/src/assets/imgs/video.png');
	}
	.other_icon{
		background-image: url('/src/assets/imgs/other.png');
	}
	.huanhang{
		display: inline-block;
		word-break: break-word;
		vertical-align: middle;
	}
</style>