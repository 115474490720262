<template>
	<div class="fileBox">
		<div class="btn_icon" @click="event.goClose"><span></span><span></span></div>
		<iframe :src="state.url" frameborder="0"></iframe>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
	import { Toast,Dialog } from '@nutui/nutui';
	import oss from 'ali-oss'
	export default {
		name:'fileP',
		props:{
			url:String
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				url: '',
			})
			onMounted(()=>{
				state.url = props.url
			})
			const event = {
				goClose(){
					context.emit('fileStatus',{fileBox: false,fileUrl:''})
				}
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.btn_icon{
		text-align: right;
		background-color: #fff;
		padding-top: 10px;
	}
	.btn_icon span{
	  display: inline-block;
	  width: 20px;
	  height: 2px;
	  background-color: #555;
	  transform-origin: center;
	}
	.btn_icon span:nth-child(1){
	  transform: rotate(45deg) translate(0px, -7px);
	}
	.btn_icon span:nth-child(2){
	  transform: rotate(135deg) translate(7px, 14px);
	}
	.fileBox{
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 2900;
	}
	.fileBox iframe{
		width: 100%;
		height: calc(100% - 20px);
	}
</style>