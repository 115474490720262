import {createRouter,createWebHashHistory} from "vue-router"


import index from '@/view/index'
import login from '@/view/user/login'
import loginNext from '@/view/user/loginNext'
import register from '@/view/user/register'
import tree from '@/view/user/tree'
import detail from '@/view/item/detail'

const routes = [
	{
	  path: '/',
	  name: 'index',
	  component: index,
	  meta:{
        title: '接案大厅',
		keepAlive: false,
		color: '#5484EA'
      }
	},
	{
	  path: '/login',
	  name: 'login',
	  component: login,
	  meta:{
	    title: '登录',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/loginNext',
	  name: 'loginNext',
	  component: loginNext,
	  meta:{
	    title: '登录',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/register',
	  name: 'register',
	  component: register,
	  meta:{
	    title: '完善信息',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/tree',
	  name: 'tree',
	  component: tree,
	  meta:{
	    title: '完善信息',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/detail',
	  name: 'detail',
	  component: detail,
	  meta:{
	    title: '详情',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
]

export const router = createRouter({
	history: createWebHashHistory(),
	routes: routes
})

export default router
