<template>
	<div class="label">
		<div class="cw">
			<div class="laberItem">
				<span>债务人姓名</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入债务人姓名" v-model="state.caseDetailInfo.icmDebtorInfo.debtorName"  /></div>
			</div>
			<div class="laberItem">
				<span>性别</span>
				<div>
					<select name="" disabled v-model="state.caseDetailInfo.icmDebtorInfo.sex">
						<option :value="item.dictValue" v-for="item in state.sexList">{{item.dictLabel}}</option>
					</select>
				</div>
			</div>
			<div class="laberItem">
				<span>债务人照片</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="imgList">
						<div class="upload_btn" v-for="item in state.imgListPF">
							<img :src="item" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="laberItem">
				<span>出生日期</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入出生日期" v-model="state.caseDetailInfo.icmDebtorInfo.birthday"  /></div>
			</div>
			<div class="laberItem">
				<span>当前年龄</span><div><input readonly type="text" placeholderl="请输入当前年龄" v-model="state.caseDetailInfo.icmDebtorInfo.age"  /></div>
			</div>
			<div class="laberItem">
				<span>户籍地址</span><div><textarea readonly name="" placeholderl="请输入户籍地址" id="" cols="30" rows="3" v-model="state.caseDetailInfo.icmDebtorInfo.birthAddressDetailed"></textarea></div>
			</div>
			<div class="laberItem">
				<span>债务人本人电话</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入债务人本人电话" v-model="state.caseDetailInfo.icmDebtorInfo.tel"  /></div>
			</div>
			<div class="laberItem">
				<span>债务人其他电话</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<table>
						<tr><td>电话</td><td>来源</td><td>备注</td></tr>
						<tr v-for="item in state.caseDetailInfo.icmDebtorInfo.otherTel">
							<td>{{item.tel}}</td><td>{{item.source}}</td><td>{{item.remark}}</td>
						</tr>
					</table>
					<!-- <input type="text" placeholderl="请输入债务人其他电话" v-model="state.caseDetailInfo.icmDebtorInfo.debtorName"  /> -->
				</div>
			</div>
			<div class="labelTit" style="text-align: left;"><span class="line_icon"></span>债务人中国居民身份证信息</div>
			<div class="laberItem">
				<span>身份证号码</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入身份证号码" v-model="state.caseDetailInfo.icmDebtorInfo.cardNum"></div>
			</div>
			<div class="laberItem">
				<span>身份证起止日期</span>
				<div style="display: flex;justify-content: space-between;align-items: center;" :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<input style="width: 40%;" readonly type="text" placeholderl="请输入身份证起止日期" v-model="state.caseDetailInfo.icmDebtorInfo.cardBeginDate">
					<span style="width: 5%;text-align: right;display: inline-block;">至</span>
					<input style="width: 40%;" readonly type="text" placeholderl="请输入身份证起止日期" v-model="state.caseDetailInfo.icmDebtorInfo.cardEndDate"></div>
			</div>
			<div class="laberItem">
				<span>身份证地址</span><div><textarea readonly name="" placeholderl="请输入身份证地址" id="" cols="30" rows="3" v-model="state.caseDetailInfo.icmDebtorInfo.cardAddressDetailed"></textarea></div>
			</div>
			<div class="laberItem">
				<span>发证机关</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入发证机关" v-model="state.caseDetailInfo.icmDebtorInfo.cardAgency"  /></div>
			</div>
			<div class="laberItem">
				<span>身份证有效期</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入身份证有效期" v-model="state.caseDetailInfo.icmDebtorInfo.cardEndDate"  /></div>
			</div>
			<div class="laberItem">
				<span>身份证照片</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="imgList">
						<div class="upload_btn" v-for="item in state.imgListIC">
							<img :src="item" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="laberItem">
				<span>其他身份照（如人脸识别）</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="imgList">
						<div class="upload_btn" v-for="item in state.imgListPO">
							<img :src="item" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';
import oss from 'ali-oss'
	export default {
		name:'people',
		props:{
			id:String,
			data:Object
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				caseData: {
					params:{
						detailsFlag:'0'
					}
				},
				caseDetailInfo:{
				  icmDebtorInfo: {
				    "createBy":null,
					"createTime":null,
					"updateBy":null,
					"updateTime":null,
					"remark":null,
					"isDelete":null,
					"params":{
						"encodeResidentialAddress":"全国/湖南省/岳阳市/汨罗市",
						"encodeCardAddress":"",
						"encodeBirthAddress":"全国/陕西省/安康市/白河县",
					},
					"ids":null,
					"id":"240321152316179350",
					"debtorName":"张三",
					"caseId":'11111111',
					"debtorPhoto":null,
					"sex":"1",
					"birthday":"2003-11-21",
					"age":"20",
					"birthAddress":"100000/610000/610900/610929",
					"birthAddressDetailed":'是对方接受甲方事件的发生积分',
					"residentialAddress":"100000/430000/430600/430681",
					"residentialAddressDetailed":null,
					"tel":"15594549938",
					"otherTel":[{tel:'12345678996',source:'李四',remark:'没啥'}],
					"cardPhotoFront":null,
					"cardPhotoBack":null,
					"otherCardPhoto":null,
					"cardBeginDate":"2020-07-06",
					"cardEndDate":"2030-07-06",
					"cardValidity":"10",
					"cardNum":"789654859621458965",
					"cardAddress":null,
					"cardAddressDetailed":"圣诞节放假士大夫精神的开发",
					"cardAgency":'中国',
					"bankName":null,
					"bankId":null,
					"otherBankInfo":null,
					"regTime":"2024-03-21 15:24:26",
					"regDate":null,
					"lastModTime":"2024-03-21",
					"lastDate":null,
					"opName":null,
					"opNo":null,
					"ext1":"app12568486655454",
					"ext2":null,
					"ext3":null,
					"ext4":null,
					"ext5":null
				  },
				},
				sexList:[],
				imgListPF: [],
				imgListIC: [require('/src/assets/imgs/card_back.png'),require('/src/assets/imgs/card_front.png')],
				imgListPO: [require('/src/assets/imgs/card_front.png')],
				imgs: [require('/src/assets/imgs/avatar11.png'),require('/src/assets/imgs/avatar22.png'),require('/src/assets/imgs/avatar33.png'),require('/src/assets/imgs/avatar44.png')],
				oss: ''
			})
			onMounted(async ()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = props.id
				state.caseData = props.data
				proxy.$common.getDict('sys_user_sex').then(data=>{
					state.sexList = data
				})
				if(state.caseData.params.detailsFlag == '0'){
					let i = Math.floor(Math.random() * 4)
					state.imgListPF.push(state.imgs[i])
					return
				}
				event.detail()
				event.selectInfosByProjectId('debtor_photo_front')
				event.selectInfosByProjectId('debtor_id_card')
				event.selectInfosByProjectId('debtor_photo_other')
			})
			const event = {
				back(){
					proxy.$router.go(-1)
				},
				detail(){
					proxy.$axios.getIcmDebtorInfoByCaseId(state.projectId).then(res=>{
						if(res.data.code == 200){
							state.caseDetailInfo.icmDebtorInfo = res.data.data
							state.caseDetailInfo.icmDebtorInfo.otherTel = JSON.parse(res.data.data.otherTel)
						}
					})
				},
				save(){
					proxy.$axios.editProjectCapitalizeInfoAndFinanceInfo({projectDetailsId:state.projectId,projectCapitalizeInfoList:state.list,projectFinanceInfoList:state.listF}).then(res =>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							proxy.$router.go(-1)
						}
					})
				},
				selectInfosByProjectId(type){
					state.imgListPF = []
					state.imgListIC = []
					state.imgListPO = []
					proxy.$axios.selectInfosByProjectId({projectDetailsId:state.projectId,scNo:type}).then(res =>{
						if(res.data.code == 200){
							if(res.data.data.length>0){
								event.getOssStsConfig(function(){
									if(type == 'debtor_photo_front'){
										res.data.data.forEach(item=>{
											state.imgListPF.push(state.oss.signatureUrl(item.fileUploadAddress))
										})
									}
									if(type == 'debtor_id_card'){
										res.data.data.forEach(item=>{
											state.imgListIC.push(state.oss.signatureUrl(item.fileUploadAddress))
										})
									}
									if(type == 'debtor_photo_other'){
										res.data.data.forEach(item=>{
											state.imgListPO.push(state.oss.signatureUrl(item.fileUploadAddress))
										})
									}
								})
							}
						}
					})
				},
				getOssStsConfig(fun){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.code == 200){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							fun && fun()
						}
					})
				},
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.imgList{
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	table{
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		margin: 5px auto;
	}
	td{
		border: 1px solid #ddd;
	}
</style>