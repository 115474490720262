<template>
  <div class="login">
	<div class="login_content">
		<div class="login_two">
			<div class="login_tit">输入手机号验证码</div>
			<div class="login_tip">请输入发送至的6位验证码</div>
			<div class="six_digit_wrapper" ref='attr'>
				<input type="tel" class="loing_input" 
				v-for="(item,index) in state.digits" 
				v-model="item.value" oninput="value=value.replace(/[^\d]/g,'')" 
				@input="event.onInput(index,item)" 
				@keyup.delete="event.onDelete(index)" 
				>
			</div>
			<div class="login_time">{{state.codeVal}}</div>
			<div class="next_btn" @click="event.login">确定</div>
		</div>
	</div>
  </div>
</template>

<script>
	import { reactive, onMounted, ref, getCurrentInstance } from 'vue';
	import { Toast,Dialog } from '@nutui/nutui';
	import axios from 'axios'
	export default {
		setup() {
			const attr = ref(null)
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				codeVal: '60s后重新发送验证码',
				codeNum: 60,
				digits:[
					{value:''},
					{value:''},
					{value:''},
					{value:''},
					{value:''},
					{value:''},
				],
				codeValue:'',
			});
			onMounted(()=>{
				event.countDown()
				attr.value.children[0].focus()
			});
			const event = {
				countDown() {
				  if (state.codeNum == 0) {
				    state.codeVal = '60s后重新发送验证码'
				    state.codeNum = 59
				    state.codeState = false
				  } else {
				    state.codeNum--
				    state.codeVal = state.codeNum + 's后重新发送验证码'
				    state.time = setTimeout(event.countDown, 1000)
				  }
				},
				onInput(index,item){
					let len = item.value.length
					if(len>1){
						let val = item.value.split('')
						for(var i = index;i<val.length+index;i++){
							state.digits[i].value = val[i-index]
						}
						attr.value.children[index+len-1].focus()
					}else{
						if(state.digits[index].value && index<5){
							attr.value.children[index+1].focus()
						}
					}
				},
				onDelete(index){
					if(index>0){
						attr.value.children[index-1].focus()
					}
				},
				closeTip(){
					Dialog({
						title: '提示',
						content: '已停止合作，请勿留存案件信息',
						noCancelBtn: true,
						closeOnClickOverlay: false,
						onOk:function(){
							if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
								WeixinJSBridge.invoke('closeWindow', {}, function(res) {
									if (res.err_msg == "close_window:ok") {
										// 关闭成功的处理
										console.log('关闭成功的处理')
									} else {
										// 关闭失败的处理
										console.log('关闭失败的处理')
										window.location.href = 'about:blank'
										window.close()
									}
								});
							}else{
								window.location.href = 'about:blank'
								window.close()
							}
						}
					});
				},
				login(){
					let psd = state.digits.map((item)=>{return item.value}).toString().replace(/,/g,'')
					if(!psd){
						return
					}
					let openid = localStorage.getItem('openid')
					proxy.$axios.login({username:proxy.$route.query.phone,password:psd,code:psd,loginType:'3',openId:openid}).then((res)=>{
						if(res.data.code == '200'){
							localStorage.setItem('token',res.data.data.access_token)
							localStorage.setItem('user',JSON.stringify({phone:proxy.$route.query.phone}))
							proxy.$axios.instance.interceptors.request.use(
							  config => {
							    config.headers['Authorization'] = res.data.data.access_token
							    return config
							  }
							)
							proxy.$axios.getInfoByPhone(proxy.$route.query.phone).then((res)=>{
								if(res.data.code == 200){
									localStorage.setItem('law_sts',res.data.data.lawSts)
									if(res.data.data.lawSts == '1' || res.data.data.lawSts == '5'){//1注册未实名，2合作中，3合作终止，4实名未审核，5游客
										proxy.$router.push({path:'/register',query:{reload:true}})
									}else if(res.data.data.lawSts == '3'){
										localStorage.removeItem('token')
										localStorage.removeItem('user')
										localStorage.removeItem('uuid')
										localStorage.removeItem('law_sts')
										event.closeTip()
									}else{
										proxy.$router.push({path:'/',query:{reload:true}})
									}
								}
							})
								
						}
					})
				}
			}
			return { 
				state,
				event,
				attr
			};
		}
	}
	
</script>

<style>
	
	.login{
		background-image: url('../../assets/imgs/top_bg.png');
		background-repeat: no-repeat;
		background-size: 100% 200px;
		background-position: top left;
		background-color: #fff;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		overflow: hidden;
	}
	.login_two{
		margin: 60px 20px 0;
		text-align: left;
	}
	.login_tit{
		color: #454545;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.login_tip{
		font-size: 13px;
		color: #333;
		margin-bottom: 45px;
	}
	.login_time{
		font-size: 13px;
		color: #A7A7A7;
		margin: 20px 0 30px;
	}
	.six_digit_wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.six_digit_wrapper input{
		display: flex;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 20px;
		color: #333333;
		background-color: #F3F3F3;
		text-align: center;
		outline: none;
		border: none;
		border-radius: 10px;
		padding: 0;
	}
</style>