<template>
	<div class="label">
		<div class="cw">
			<div class="laberItem">
				<span>出借人原始债权人机构名称</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<select name="" disabled v-model="state.caseDetailInfo.icmPactInfo.creditId">
						<option :value="item.id" v-for="item in state.creditorList">{{item.agencyName}}</option>
					</select>
				</div>
			</div>
			<div class="laberItem">
				<span>合同原本</span>
				<div :class="{'flieList':true, 'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="file_btn" v-for="item in state.pactFile" @click="event.fileClick(item)">
						<span  :class="{'file_icon':true,
						'word_icon':['txt','doc','docx','log'].indexOf(item.type) != -1,
						'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(item.type) != -1,
						'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(item.type) != -1,
						'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(item.type) != -1,
						'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(item.type) != -1,
						'pdf_icon':['pdf'].indexOf(item.type) != -1
						}"></span>
						<span class="huanhang">{{item.name}}</span>
					</div>
				</div>
			</div>
			<div class="laberItem">
				<span>借款产品名称</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入借款产品名称" v-model="state.caseDetailInfo.icmPactInfo.kindName"/></div>
			</div>
			<div class="laberItem">
				<span>借款分类</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<select disabled name="" v-model="state.caseDetailInfo.icmPactInfo.loanType">
						<option :value="item.dictValue" v-for="item in state.loanType">{{item.dictLabel}}</option>
					</select>
				</div>
			</div>
			<div class="laberItem">
				<span>借款合同编号</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入借款合同编号" v-model="state.caseDetailInfo.icmPactInfo.pactNo"  /></div>
			</div>
			<div class="laberItem">
				<span>借款订单号</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入借款订单号" v-model="state.caseDetailInfo.icmPactInfo.pactAppId"  /></div>
			</div>
			<div class="laberItem">
				<span>合同约定司法管辖地类型</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<select disabled name="" v-model="state.caseDetailInfo.icmPactInfo.pactRole">
						<option :value="item.dictValue" v-for="item in state.pactRole">{{item.dictLabel}}</option>
					</select>
				</div>
			</div>
			<div class="laberItem">
				<span>管辖地</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入管辖地" v-model="state.caseDetailInfo.icmPactInfo.params.encodePactVenueAddress"  /></div>
			</div>
			<div class="laberItem">
				<span>合同状态</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<select disabled name="" v-model="state.caseDetailInfo.icmPactInfo.pactSts">
						<option :value="item.dictValue" v-for="item in state.pactSts">{{item.dictLabel}}</option>
					</select>
				</div>
			</div>
			<div class="laberItem">
				<span>合同签订日</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<input type="text" readonly @click="state.showDateStart = true" placeholderl="请选择合同签订日" v-model="state.caseDetailInfo.icmPactInfo.pactContractDate"  />
					<!-- <nut-datepicker
					      v-model="state.caseDetailInfo.icmPactInfo.pactContractDate"
					      v-model:visible="state.showDateStart"
					      :is-show-chinese="true"
					      :three-dimensional="false"
					      @confirm="event.confirm"
					  ></nut-datepicker> -->
				</div>
			</div>
			<div class="laberItem">
				<span>合同开始日</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<input type="text" readonly @click="state.showDateStart = true" placeholderl="请选择合同开始日" v-model="state.caseDetailInfo.icmPactInfo.pactBeginDate"  />
					<!-- <nut-datepicker
					      v-model="state.caseDetailInfo.icmPactInfo.pactBeginDate"
					      v-model:visible="state.showDateStart"
					      :is-show-chinese="true"
					      :three-dimensional="false"
					      @confirm="event.confirm"
					  ></nut-datepicker> -->
				</div>
			</div>
			<div class="laberItem">
				<span>合同结束日</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<input type="text" readonly @click="state.showDateStart = true" placeholderl="请选择合同开始日" v-model="state.caseDetailInfo.icmPactInfo.pactEndDate"  />
					<!-- <nut-datepicker
					      v-model="state.caseDetailInfo.icmPactInfo.pactEndDate"
					      v-model:visible="state.showDateStart"
					      :is-show-chinese="true"
					      :three-dimensional="false"
					      @confirm="event.confirm"
					  ></nut-datepicker> -->
				</div>
			</div>
			<div class="laberItem">
				<span>合同经办人姓名</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入合同经办人姓名" v-model="state.caseDetailInfo.icmPactInfo.pactOperatorName"/></div>
			</div>
			<div class="laberItem">
				<span>合同经办人电话</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input readonly type="text" placeholderl="请输入合同经办人电话" v-model="state.caseDetailInfo.icmPactInfo.pactOperatorTel"  /></div>
			</div>
			<div class="laberItem">
				<span>合同申请时材料</span>
				<div :class="{'flieList':true, 'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="file_btn" v-for="item in state.pactApplyFile" @click="event.fileClick(item)">
						<span  :class="{'file_icon':true,
						'word_icon':['txt','doc','docx','log'].indexOf(item.type) != -1,
						'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(item.type) != -1,
						'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(item.type) != -1,
						'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(item.type) != -1,
						'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(item.type) != -1,
						'pdf_icon':['pdf'].indexOf(item.type) != -1
						}"></span>
						<span class="huanhang">{{item.name}}</span>
					</div>
				</div>
			</div>
			<div class="laberItem" style="border: none;">
				<span>合同其他证明材料</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="flieList">
						<div class="file_btn" v-for="item in state.pactOtherFile" @click="event.fileClick(item)">
							<span  :class="{'file_icon':true,
							'word_icon':['txt','doc','docx','log'].indexOf(item.type) != -1,
							'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(item.type) != -1,
							'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(item.type) != -1,
							'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(item.type) != -1,
							'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(item.type) != -1,
							'pdf_icon':['pdf'].indexOf(item.type) != -1
							}"></span>
							<span class="huanhang">{{item.name}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="labelTit" style="text-align: left;"><span class="line_icon"></span>合同涉商户和商户订单信息</div>
			<div class="laberItem">
				<span>合同涉商户的商户名称</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input type="text" readonly placeholderl="请输入合同涉商户的商户名称" v-model="state.caseDetailInfo.icmPactInfo.pactTrenchName"  /></div>
			</div>
			<div class="laberItem">
				<span>商户经营地址</span><div><input type="text" readonly placeholderl="请输入商户经营地址" v-model="state.caseDetailInfo.icmPactInfo.params.encodeTrenchAddress"  /></div>
			</div>
			<div class="laberItem">
				<span>商户项目订单号</span><div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}"><input type="text" readonly placeholderl="请输入商户项目订单号" v-model="state.caseDetailInfo.icmPactInfo.trenchAppId"  /></div>
			</div>
			<div class="laberItem">
				<span>合同中约定的商户提供产品服务、用途、目的</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<textarea name="" placeholderl="请输入合同中约定的商户提供产品服务、用途、目的"  id="" cols="30" rows="5" v-model="state.caseDetailInfo.icmPactInfo.kindDescribe"></textarea>
				</div>
			</div>
			<div class="laberItem">
				<span>合同其他备注信息</span>
				<div :class="{'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<textarea name="" id="" placeholderl="请输入合同其他备注信息" cols="30" rows="5" v-model="state.caseDetailInfo.icmPactInfo.pactRemark"></textarea>
				</div>
			</div>
			<div class="laberItem" style="border: none;">
				<span>项目交付证明（如交付照、发货单、术后照、签收凭据等）</span>
				<div :class="{'flieList':true, 'mosaic':state.caseData.params.detailsFlag == '1' && state.caseData.params.dispatchCaseFlag != '1'}">
					<div class="file_btn" v-for="item in state.trenchOtherFile" @click="event.fileClick(item)">
						<span  :class="{'file_icon':true,
						'word_icon':['txt','doc','docx','log'].indexOf(item.type) != -1,
						'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(item.type) != -1,
						'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(item.type) != -1,
						'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(item.type) != -1,
						'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(item.type) != -1,
						'pdf_icon':['pdf'].indexOf(item.type) != -1
						}"></span>
						<span class="huanhang">{{item.name}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';
import oss from 'ali-oss'
	export default {
		name:'contract',
		props:{
			id:String,
			data: Object
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				caseData: {
					params:{
						detailsFlag:'0'
					}
				},
				info:{
					dateStart: ''
				},
				showDateStart: false,
				fileBox: false,
				fileUrl: '',
				caseDetailInfo:{
				  icmPactInfo: {
				    "createBy":null,
					"createTime":null,
					"updateBy":null,
					"updateTime":null,
					"remark":null,
					"isDelete":null,
					"params":{
						"encodeTrenchAddress":"全国/上海市/市辖区/青浦区",
						"encodePactVenueAddress":"",
					},
					"ids":null,
					"id":"1234567899856632563",
					"caseId":111111111,
					"creditId":23121321231,
					"creditName":null,
					"pactPdf":null,
					"kindName":"带带带",
					"loanType":"4",
					"pactNo":"MF555646541353",
					"pactAppId":"app5413215612315",
					"pactRole":"2",
					"pactVenueAddress":null,
					"pactSts":"6",
					"pactContractDate":"2022-06-30",
					"pactBeginDate":"2022-06-30",
					"pactEndDate":"2023-06-30",
					"pactOperatorName":"张三",
					"pactOperatorTel":"1234678966",
					"pactApplyFile":null,
					"pactOtherFile":null,
					"pactTrenchName":"美容么人",
					"trenchAddress":"100000/310000/310100/310118",
					"trenchAppId":null,
					"kindDescribe":"吸脂",
					"pactRemark":null,
					"payProveFile":null,
					"regTime":"2024-03-21",
					"regDate":null,
					"lastModTime":"2024-03-22",
					"lastDate":null,
					"opName":null,
					"opNo":null,
					"ext1":null,
					"ext2":null,
					"ext3":null,
					"ext4":null,
					"ext5":null,
					"trenchAddressDetailed":"沙发沙发沙发沙发色微软微软微软微软为非法温热为"
				  },
				},
				creditorList:[],//机构
				loanType:[],//借款分类
				pactSts:[],//合同状态
				pactRole:[],//司法管辖地类型
				pactFile:[
					{"type":"pdf","name":"授权书.pdf"},
					{"type":"word","name":"个人信息查询授权书.word"}
				],
				pactApplyFile:[
					{"type":"xls","name":"授权书.xls"},
					{"type":"pdf","name":"个人信息查询授权书.pdf"}
				],
				pactOtherFile:[
					{"type":"png","name":"授权书.png"},
					{"type":"pdf","name":"个人信息查询授权书.pdf"}
				],
				trenchOtherFile:[
					{"type":"mp4","name":"授权书.mp4"},
					{"type":"ppt","name":"个人信息查询授权书.ppt"}
				],
				law_sts: '5'
				
			})
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = props.id
				state.caseData = props.data
				state.law_sts = localStorage.getItem('law_sts')
				proxy.$common.getDict('loan_type').then(data=>{
					state.loanType = data
				})
				proxy.$common.getDict('pact_role').then(data=>{
					state.pactRole = data
				})
				proxy.$common.getDict('pact_sts').then(data=>{
					state.pactSts = data
				})
				proxy.$common.getAll().then(data=>{
					state.creditorList = data
				})
				if(state.caseData.params.detailsFlag == '0') return
				event.detail()
				event.selectInfosByProjectId('pact_file')
				event.selectInfosByProjectId('pact_apply_file')
				event.selectInfosByProjectId('pact_other_file')
				event.selectInfosByProjectId('trench_other_file')
			})
			const event = {
				getStatus(res){
					state.fileBox = res
				},
				confirm(selectedValue, selectedOptions){
					
				},
				back(){
					proxy.$router.go(-1)
				},
				detail(){
					proxy.$axios.getIcmPactInfoByCaseId(state.projectId).then(res=>{
						if(res.data.code == 200){
							state.caseDetailInfo.icmPactInfo = res.data.data
						}
					})
				},
				save(){
					proxy.$axios.editProjectCapitalizeInfoAndFinanceInfo({projectDetailsId:state.projectId,projectCapitalizeInfoList:state.list,projectFinanceInfoList:state.listF}).then(res =>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							proxy.$router.go(-1)
						}
					})
				},
				selectInfosByProjectId(type){
					state.pactFile = []
					state.pactOtherFile = []
					state.pactApplyFile = []
					state.trenchOtherFile = []
					proxy.$axios.selectInfosByProjectId({projectDetailsId:state.projectId,scNo:type}).then(res =>{
						if(res.data.code == 200){
							if(res.data.data.length>0){
								event.getOssStsConfig(function(){
									if(type == 'pact_file'){
										res.data.data.forEach(item=>{
											state.pactFile.push({url:state.oss.signatureUrl(item.fileUploadAddress),name:item.fileName,type:item.fileType})
										})
									}
									if(type == 'pact_other_file'){
										res.data.data.forEach(item=>{
											state.pactOtherFile.push({url:state.oss.signatureUrl(item.fileUploadAddress),name:item.fileName,type:item.fileType})
										})
									}
									if(type == 'pact_apply_file'){
										res.data.data.forEach(item=>{
											state.pactApplyFile.push({url:state.oss.signatureUrl(item.fileUploadAddress),name:item.fileName,type:item.fileType})
										})
									}
									if(type == 'trench_other_file'){
										res.data.data.forEach(item=>{
											state.trenchOtherFile.push({url:state.oss.signatureUrl(item.fileUploadAddress),name:item.fileName,type:item.fileType})
										})
									}
								})
							}
						}
					})
				},
				getOssStsConfig(fun){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.code == 200){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							fun && fun()
						}
					})
				},
				fileClick(item){
					if(state.law_sts == '1' || state.law_sts == '2' || state.law_sts == '3' || state.law_sts == '4' || state.law_sts == '5') return
					context.emit('fileStatus',{fileBox:true,fileUrl:'https://view.xdocin.com/view?src='+encodeURIComponent(item.url)})
				}
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.flieList{
		height: 88px;
		overflow-y: scroll;
	}
	.file_btn{
		display: flex;
		width: 100%;
		align-items: center;
		margin: 5px 0;
	}
	.file_icon{
		display: inline-block;
		width: 26px;
		height: 32px;
		background-size: 100%;
		background-position: top center;
		background-repeat: no-repeat;
		vertical-align: middle;
		margin-right: 5px;
		background-image: url('/src/assets/imgs/other.png');
	}
	.jpg_icon{
		background-image: url('/src/assets/imgs/jpg.png');
	}
	.excel_icon{
		background-image: url('/src/assets/imgs/excel.png');
	}
	.ppt_icon{
		background-image: url('/src/assets/imgs/ppt.png');
	}
	.pdf_icon{
		background-image: url('/src/assets/imgs/pdf.png');
	}
	.word_icon{
		background-image: url('/src/assets/imgs//word.png');
	}
	.video_icon{
		background-image: url('/src/assets/imgs/video.png');
	}
	.other_icon{
		background-image: url('/src/assets/imgs/other.png');
	}
	.huanhang{
		display: inline-block;
		word-break: break-word;
		vertical-align: middle;
		width: calc(100% - 26px);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>